/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * sofia-pro:
 *   - http://typekit.com/eulas/0000000000000000000157ad
 *   - http://typekit.com/eulas/0000000000000000000157b1
 *   - http://typekit.com/eulas/0000000000000000000157b3
 *
 * © 2009-2019 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2017-11-30 11:05:04 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=lck0ocu&ht=tk&f=24543.24547.24549&a=930642&app=typekit&e=css");

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/07553a/0000000000000000000157ad/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),
    url("https://use.typekit.net/af/07553a/0000000000000000000157ad/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),
    url("https://use.typekit.net/af/07553a/0000000000000000000157ad/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/c07bfb/0000000000000000000157b1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
    url("https://use.typekit.net/af/c07bfb/0000000000000000000157b1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
    url("https://use.typekit.net/af/c07bfb/0000000000000000000157b1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/83e419/0000000000000000000157b3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),
    url("https://use.typekit.net/af/83e419/0000000000000000000157b3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),
    url("https://use.typekit.net/af/83e419/0000000000000000000157b3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-style: normal;
    font-weight: 600;
}

.tk-sofia-pro,
body {
    font-family: "sofia-pro", sans-serif;
}

:root {
    background-color: var(--theme-primary-color, hsl(260, 71%, 50%));
}

body {
    background-color: white;
}

a:link,
a:visited {
    color: var(--theme-link-color, hsl(195, 99%, 63%));
}

a:hover,
a:active {
    color: var(--theme-link-hover-color, hsl(195, 99%, 43%));
}

strong {
    font-weight: 600;
}

em {
    font-style: italic;
}

kbd {
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
    0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
    color: #333;
    display: inline-block;
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
    font-family: monospace;
}

/* Material Overrides */

.MuiTabs-indicator.MuiTabs-indicator,
.MuiButton-containedPrimary.MuiButton-containedPrimary {
    background-color: var(--theme-primary-color, hsl(260, 71%, 50%));
}

a.MuiButton-root {
    color: var(--theme-button-text-color, white);
}

.MuiMenuItem-root.MuiMenuItem-root,
.MuiInputBase-root.MuiInputBase-root,
.MuiFormLabel-root.MuiFormLabel-root {
    font-family: inherit;
}


/* Freshdesk Overrides */
/* 
  This is somewhat a hack to hide Freshdesk's Feeback button so we can trigger it from our own UI 
  If at any point Freshdesk change their HTML IDs then we will need to update the CSS selector here.
  Failing that, we will need to consider the UI to take into account Freshdesk's feedback widget button
*/
#freshworks-container #launcher-frame {
    display: none;
}
