.error_message{
    margin-top:40vh;
    width: 90vw;
    text-align: center;
}
.error_message h1 {
    font-size: 300%;
}
.error_message h3 {
    margin-top: 10vh;
    font-size: 200%;
}