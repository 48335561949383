.WorkshopNav {
    width: 45px;
    flex: 0 0 45px;
    background-color: hsl(210, 17%, 13%);
}

.WorkshopNav__back,
.WorkshopNav__back:link,
.WorkshopNav__back:visited {
    color: white;
}

.WorkshopNav__container {
    flex: 0 0;
    align-self: flex-start;
    top: 0;
    bottom: 0;
    overflow: hidden;
    color: white;
    display: grid;
    grid-gap: 20px;
    align-items: center;
    padding: 7rem 10px;
}

.WorkshopNav__top {
    display: grid;
    grid-gap: 10px;
    align-self: flex-start;
}

.WorkshopNav__list {
    font-size: 0.8rem;
    margin-top: auto;
    margin-bottom: auto;
}

.WorkshopNav__navLink,
.WorkshopNav__navLink:link,
.WorkshopNav__navLink:visited {
    display: block;
    margin: 0.3rem 0;
    text-align: center;
    color: white;
}

.WorkshopNav__navLink.WorkshopNav__navLink--active {
    color: var(--theme-link-color, hsl(195, 99%, 63%));
}

.WorkshopNav__navLink.WorkshopNav__navLink--active circle {
    box-shadow: 0 0 5px var(--theme-link-color, hsl(195, 99%, 63%, 1));
}

.WorkshopNav__listItem.WorkshopNav__listItem--hidden {
    visibility: hidden;
}

.WorkshopNav__button {
    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;
    color: inherit;
    /* margin: 1rem 0; */
    cursor: pointer;
}

.WorkshopNav__menuItem {
    display: flex;
    align-items: center;
}

.WorkshopNav__menuItem span {
    margin-bottom: 3px;
    margin-left: 10px;
}
