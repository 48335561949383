.App {
    display: flex;
    flex-flow: row nowrap;
    align-content: stretch;
    align-items: stretch;
    height: 100vh;
    overflow: hidden;
}

.App.App--checking {
    padding: 0;
    justify-content: center;
    align-items: center;
}
