.UserMenu__definitionsItem {
  padding: 0 16px;
  margin: 1rem 0;
}

.UserMenu__definitionsItem dt {
  font-size: 0.825rem;
  font-weight: 300;
  margin-bottom: 0.25rem;
}

.UserMenu__definitionsItem dd {
  font-weight: 400;
}
