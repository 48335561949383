:root {
  --theme-primary-color: hsl(260, 71%, 50%);
  --theme-primary-color-highlight: hsl(260, 71%, 60%);
  --theme-secondary-color: hsl(195, 99%, 63%);
  --theme-secondary-color-highlight: hsl(195, 99%, 43%);

  --theme-link-color: var(--theme-secondary-color);
  --theme-link-hover-color: var(--theme-secondary-color-highlight);

  --theme-button-color: var(--theme-primary-color);
  --theme-button-text-color: white;
  --theme-button-hover-color: var(--theme-primary-color-highlight);

  --theme-logo-background-color: var(--theme-primary-color);
  --theme-logo-hover-background-color: var(--theme-primary-color-highlight);

  --theme-error-color: hsl(3, 100%, 60%);
  --theme-error-highlight-color: hsl(3, 75%, 80%);
  --theme-warning-color: hsl(52, 100%, 50%);

  --theme-beginner-color: hsl(348, 100%, 69%);
  --theme-beginner-hover-color: hsl(348, 100%, 75%);
  --theme-intermediate-color: hsl(195, 99%, 63%);
  --theme-intermediate-hover-color: hsl(195, 99%, 68%);
  --theme-advanced-color: hsl(255, 93%, 69%);
  --theme-advanced-hover-color: hsl(255, 93%, 74%);
}
