.WorkshopCard {
  margin: 2rem 0;
  display: block;
  background-color: var(--theme-beginner-color);
  color: white;
  padding: 1.5rem 20px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.WorkshopCard:link,
.WorkshopCard:visited {
  color: white;
  text-decoration: none;
}

.WorkshopCard:hover,
.WorkshopCard:active {
  background-color: var(--theme-beginner-hover-color);
}

.WorkshopCard.WorkshopCard--intermediate {
  background-color: var(--theme-intermediate-color);
}

.WorkshopCard.WorkshopCard--intermediate:hover,
.WorkshopCard.WorkshopCard--intermediate:active {
  background-color: var(--theme-intermediate-hover-color);
}

.WorkshopCard.WorkshopCard--advanced {
  background-color: var(--theme-advanced-color);
}

.WorkshopCard.WorkshopCard--advanced:hover,
.WorkshopCard.WorkshopCard--advanced:active {
  background-color: var(--theme-advanced-hover-color);
}

.WorkshopCard__article {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 20px;
  line-height: 1.5;
  font-weight: 300;
}

.WorkshopCard__title {
  font-size: 1.5rem;
  font-weight: normal;
}

.WorkshopCard__difficulty {
  margin-top: 1.5rem;
}
