.Help__nav {
  background-color: rgb(29, 35, 41);
  color: white;
  padding: 7rem 30px 2rem;
  display: flex;
  flex-direction: column;
}

.Help__navTitle {
  color: white;
  font-size: 2.125rem;
  margin-bottom: 2rem;
}

.Help__content {
  padding: 7rem 30px 2rem;
}

a.Help__link,
a.Help__link:link,
a.Help__link:visited {
  color: rgb(79, 87, 92);
  text-decoration: none;
  font-size: 1.25rem;
  margin: 0.75rem 0;
  display: inline-block;
  line-height: 1.3;
}

a.Help__link.Help__link--active,
a.Help__link:hover,
a.Help__link:active {
  color: white;
  text-decoration: none;
}

.Help__versionTitle {
  font-size: 0.75rem;
}

.Help__version {
  margin: auto 0 0 0;
  font-size: 0.625rem;
  line-height: 1.5;
  color: rgb(79, 87, 92);
}

.Help__version:hover {
  color: white;
}
