.Login {
  background-color: #aaa;
}

.Login__left,
.Login__right {
  padding: 20px;
}

.Login__left {
  background-color: #1d2329;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Login__title {
  font-size: 2.125rem;
  margin-bottom: 2rem;
}

.Login__left p,
.Login__left ul {
  margin: 1rem 0;
  line-height: 1.5rem;
}

.Login__left ul {
  list-style: disc outside;
  padding-left: 2rem;
}

.Login__button {
  margin: 2rem 0;
}

.Login__right {
  background-image: linear-gradient(
      0deg,
      rgba(29, 35, 41, 0.5),
      rgba(29, 35, 41, 0.5)
    ),
    url(./flightdeck-background.jpg);
  background-size: cover;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login__logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login__logoImage {
  width: 70px;
  height: 70px;
  background-color: var(--theme-logo-background-color, hsl(260, 71%, 50%));
  border-radius: 18px;
  padding: 15px;
}
