.Workshop {
    overflow: auto;
    flex: 1;
}

.Workshop__description {
    line-height: 1.3;
    color: #4f575c;
    margin: 1rem 0;
}

.Workshop__main {
    padding: 7rem 30px 2rem;
    background-color: rgb(244, 245, 247);
    min-height: 100%;
}

.Workshop__title {
    font-size: 2.125rem;
    margin-bottom: 2rem;
}