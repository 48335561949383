.CourseDetail {
  background-color: white;
  padding: 7rem 30px;
}

.CourseDetail__title {
  font-size: 2.125rem;
  margin-bottom: 2rem;
}

.CourseDetail__description {
  line-height: 1.3;
  color: #4f575c;
  margin: 1rem 0;
}

.CourseDetail__workshops {
  background-color: rgb(244, 245, 247);
}

.CourseDetail__back {
  margin: 0 0 1rem;
}

.CourseDetail__backText {
  margin-left: 1ch;
}

.CourseDetail__backdrop{
  z-index: 1201;
  color: #fff;
}
