div.OfflineWarning__container {
  background-color: var(--theme-secondary-color);
  color: black;
}

.OfflineWarning__content {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  align-items: center;
}
