.HomeContent {
  flex: 1;
  padding: 3rem;
  background-image: url(../Login/flightdeck-background.jpg);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.HomeContent__content {
  background: hsla(210, 17%, 13%, 0.95);
  flex: 1;
  padding: 4rem;
  max-width: 800px;
  margin: auto;
}

.HomeContent__copy p {
  margin: 2rem 0;
  line-height: 1.4;
}

.HomeContent__title {
  font-size: 2.125rem;
  margin-bottom: 1rem;
}

.HomeContent__subtitle {
  font-size: 1.6rem;
  margin-bottom: 3rem;
}

.HomeContent__buttonText {
  margin-left: 10px;
}
