.NotFound {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.NotFound__icon {
  color: var(--theme-primary-color, hsl(260, 71%, 50%));
  font-size: 5rem;
}
