.Toolbar {
    flex: 0 0 80px;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 20px 15px;
    color: white;
    background-color: #16191c;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    align-items: center;
    grid-gap: 20px;
    transition-delay: 50ms;
    transition-duration: 0.3s;
    transition-property: width, box-shadow;
    transition-timing-function: ease-in-out;
    overflow: hidden;
}

button.Toolbar__navItem {
    appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    display: flex;
    width: 100%;
    text-align: left;
}

.Toolbar__navItem {
    padding-left: 5px;
}

.Toolbar__navItem,
.Toolbar__navItem:link,
.Toolbar__navItem:visited {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    position: relative;
}

.Toolbar__navItem:hover,
.Toolbar__navItem:active,
.Toolbar__navItem:focus,
.Toolbar__navItem.Toolbar__navItem--active {
    color: white;
}

.Toolbar__navItemIcon {
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #262e35;
    display: inline-flex;
    border-radius: 12px;
}

.Toolbar .Toolbar__logout,
.Toolbar .Toolbar__logout:hover {
    background-color: white;
    color: black;
    width: 50px;
    height: 50px;
}

.Toolbar__logo {
    padding: 12px;
    width: 50px;
    height: 50px;
    background-color: var(--theme-logo-background-color, hsl(260, 71%, 50%));
    border-radius: 18px;
}

.Toolbar__logo:hover {
    background-color: var(
            --theme-logo-hover-background-color,
            hsl(260, 71%, 60%)
    );
}

.Toolbar__logo img {
    width: 100%;
    height: auto;
    display: block;
}

.Toolbar__bottom {
    display: flex;
    justify-content: flex-start;
}

.Toolbar__bottomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
