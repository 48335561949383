.Courses {
  background-color: white;
  display: flex;
  flex: 1;
}

.Courses__list {
  background-color: rgb(29, 35, 41);
  padding: 7rem 30px;
  color: white;
}

.Courses__body {
  flex: 1;
}

.Courses__main {
  padding: 7rem 30px 2rem;
  flex: 1;
  min-height: 100vh;
  background-color: rgb(244, 245, 247);
}

.Courses__title {
  font-size: 2.125rem;
  margin-bottom: 2rem;
}

.Courses__intro {
  margin: 1rem 0 2rem;
}

.Courses__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  align-items: stretch;
}

.Courses__link {
  display: flex;
}

.Courses__link,
.Courses__link:link,
.Courses__link:visited {
  text-decoration: none;
}

.Courses__card {
  padding: 2rem 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Courses__card h2 {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  padding-left: 10px;
  padding-bottom: 5px;
  border-left: 3px solid;
}

.Courses__card.Courses__card--beginner h2 {
  border-left-color: var(--theme-beginner-color);
}

.Courses__card.Courses__card--intermediate h2 {
  border-left-color: var(--theme-intermediate-color);
}

.Courses__card.Courses__card--advanced h2 {
  border-left-color: var(--theme-advanced-color);
}

.Courses__card p {
  color: hsl(0, 0%, 50%);
  line-height: 1.5;
}

.Courses__difficulty {
  font-size: 0.8rem;
  margin-top: auto;
  padding-top: 2rem;
}

.Courses__page {
  min-height: 100vh;
}
