button.Button {
    appearance: none;
    color: white;
    border: 0;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    cursor: pointer;
}

a.Button {
    color: white;
}

a.Button:link,
a.Button:visited {
    color: white;
}


.Button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.7rem 20px;
    background-color: var(--theme-button-color, hsla(260, 71%, 50%, 1));
    text-decoration: none;
    border-radius: 70px;
    width: 100%;
}

.Button:hover,
.Button:active {
    background-color: var(--theme-button-hover-color, hsla(260, 71%, 60%, 1));
}

.Button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
